'use client'

import type { AxiosError } from '@atnd/axios'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import { useOrganizationId } from '@hooks/useOrganizationId'

import { headerHeight } from '../../constants/size'
import { pagesPath } from '../../utils/$path'

export default function ErrorPage({
	error,
}: {
	error: Error & { digest?: string }
}) {
	const organizationId = useOrganizationId()

	useEffect(() => {
		console.error(error)
		Sentry.addBreadcrumb({
			message: error.message,
			data: {
				cause: error.cause,
				name: error.name,
				stack: error.stack,
				digest: error.digest,
			},
			category: 'unknown',
		})
	}, [error])

	const axiosError = error as AxiosError
	const statusCode = axiosError.response?.status
	const errorMessage = getErrorMessage(statusCode)
	return (
		<div style={containerStyle}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					paddingBottom: 8,
				}}
			>
				<div style={{ ...headerStyle, fontSize: 40, marginBottom: -8 }}>{statusCode}</div>
				<div style={headerStyle}>{errorMessage.title}</div>
			</div>
			<div style={subheaderStyle}>{errorMessage.description}</div>
			{statusCode === 401 || error.cause === authErrorCause ? (
				<a
					href={pagesPath._lang('ja').organizations._organizationId(organizationId).login.$url().path}
					style={buttonStyle}
				>
					ログイン画面へ
				</a>
			) : (
				<a
					href={pagesPath._lang('ja').organizations._organizationId(organizationId).user.$url().path}
					style={buttonStyle}
				>
					トップページへ
				</a>
			)}
		</div>
	)
}

const getErrorMessage = (statusCode?: number) => {
	switch (statusCode) {
		case 400:
			return {
				title: `Bad Request`,
				description: 'リクエストが不正です',
			}
		case 401:
			return {
				title: `Unauthorized`,
				description: '認証が必要です',
			}
		case 403:
			return {
				title: `Forbidden`,
				description: 'アクセスが禁止されています',
			}
		case 404:
			return {
				title: `Not Found`,
				description: 'リソースが見つかりません',
			}
		case 500:
			return {
				title: `Internal Server Error`,
				description: 'サーバーでエラーが発生しました',
			}
		case 503:
			return {
				title: `Service Unavailable`,
				description: 'サービスが一時的に使用不可です',
			}
		default:
			return {
				title: `Unknown Error`,
				description: 'エラーが発生しました',
			}
	}
}

const containerStyle = {
	height: `calc(100dvh - ${headerHeight}px)`,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
} as const

const headerStyle = {
	fontSize: '32px',
	fontWeight: 'bold',
	color: 'cadetblue',
}

const subheaderStyle = {
	fontSize: '18px',
	color: '#757575',
}

const buttonStyle = {
	marginTop: '16px',
	margin: '20px 0',
	padding: '10px 20px',
	backgroundColor: '#teal',
	color: 'cornflowerblue',
	fontSize: '16px',
	fontWeight: 'bold',
	borderRadius: '4px',
	cursor: 'pointer',
	transition: 'background-color 0.3s',
}

const authErrorCause = 'token_expired'
